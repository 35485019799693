import ReactModal from "react-modal"
import {FadeLoader} from 'react-spinners'
const LoadingSpinner = ({isOpen}) => {
    return (
        <ReactModal
            isOpen={isOpen}
            ariaHideApp={false}
            onAfterOpen={() => {
                document.body.style.overflow = "hidden"
            }}
            onAfterClose={() => {
                document.body.style.overflow = "auto"
            }}
            style={{
                content: {
                    position: "absolute", top: "50%", left: "50%", right: "auto", bottom: "auto",
                    transform: "translate(-50%,-50%)", overflow:"hidden", background:"transparent",
                    display: "flex", padding: "none", border: "none"
                }
            }}>
            <FadeLoader color="#941949" />
        </ReactModal>
    )
}

export default LoadingSpinner