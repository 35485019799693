import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import SexSelector from "./components/SexSelector";
import SizedBox from "./components/SizedBox";
import TextInput from "./components/TextInput";
import DivideLine from "./components/DivideLine";
import RangeSelector from "./components/RangeSelector";
import Selector from "./components/Selector";
import PippModal from "./components/PippModal";
import { sendCollector, sendEnrollment } from "./functions/enrollmentAPI";
import { _isEmailValid, _isPhoneValid } from "./functions/checkIsValid";
import Bucket from "./ImageUriContainer";
import LoadingSpinner from "./components/LoadingSpinner";
import enrollmentStyle from "./Enrollment.css";

const max360Width = {
    width: "100%",
    maxWidth: "360px",
}

const CollectorEnrollment = () => {
    const navigate = useNavigate();
    const [sex, setSex] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [artPrefer, setArtPrefer] = useState(undefined);
    const [age, setAge] = useState("");
    //useState how many visit museum in a year
    const [visitMuseum, setVisitMuseum] = useState(undefined);
    //useState Maxium price of one art
    const [maxPrice, setMaxPrice] = useState(undefined);
    //useState prefer price of on art
    const [preferPrice, setPreferPrice] = useState(undefined);
    //UseState agree use personal information
    const [agree, setAgree] = useState(false);
    //useSTate personal infomation modal visible
    const [modalVisible, setModalVisible] = useState(false);

    const [loading, setLoading] = useState(false);

    const _onClickBackBtn = () => {
        navigate(-1);
    }

    const _toggleAgree = () => {
        setAgree(!agree);
    }
    //const arrow function that toggle modal visible
    const _toggleModalVisible = () => {
        setModalVisible(!modalVisible);
    };
    //arrow function that submit button is clicked
    const _onClickSubmitBtn = async () => {
        if (!_checkIsAllValid()) {
            return;
        }
        const value = {
            "gender": sex,
            "name": name,
            "phone": phone,
            "email": email,
            "artPrefer": artPrefer,
            "age": age,
            "visitMuseum": visitMuseum,
            "maxPrice": maxPrice,
            "preferPrice": preferPrice,
            "agree": agree
        }
        try {
            setLoading(true)
            await sendEnrollment(value, "collector")
            alert("제출되었습니다");
            navigate("/enrollment", {
                replace: true
            });
        } catch (error) {
            console.log(error)
            alert("정보 저장 중 오류 발생.")
        } finally {
            setLoading(false)
        }
    }

    const _checkIsAllValid = () => {
        //check sex value is not empty or undefined
        if (sex === "" || sex === undefined) {
            alert("성별을 선택해주세요");
            return false;
        }
        //check name value is not empty or undefined
        if (name === "" || name === undefined) {
            alert("이름을 입력해주세요");
            return false;
        }
        //check phone value is not empty or undefined
        if (!_isPhoneValid(phone)) {
            alert("전화번호를 입력해주세요");
            return false;
        }
        //check email value is not empty or undefined
        if (!_isEmailValid(email)) {
            alert("이메일을 올바르게 입력해주세요");
            return false;
        }
        //check art prefer value is not empty or undefined
        if (artPrefer === "" || artPrefer === undefined) {
            alert("관심도를 선택해주세요");
            return false;
        }
        //check age value is not empty or undefined
        if (age === "" || age === undefined) {
            alert("연령대를 선택해주세요");
            return false;
        }
        //check visit museum value is not empty or undefined
        if (visitMuseum === "" || visitMuseum === undefined) {
            alert("방문횟수를 선택해주세요");
            return false;
        }
        //check max price value is not empty or undefined
        if (maxPrice === "" || maxPrice === undefined) {
            alert("최대 지불 가능 금액을 선택해주세요");
            return false;
        }
        //check prefer price value is not empty or undefined
        if (preferPrice === "" || preferPrice === undefined) {
            alert("선호가격대를 선택해주세요");
            return false;
        }
        //check agree value is not empty or undefined
        if (!agree) {
            alert("개인정보 수집 및 이용에 동의해주세요");
            return false;
        }
        return true;
    }

    return (
        <div style={{
            display: "flex", flexDirection: "column",
            alignItems: "center", maxWidth: "720px", overflow: "hidden",
            position: "relative", margin: "auto"
        }}>
            <LoadingSpinner isOpen={loading} />
            <PippModal isOpen={modalVisible}
                onRequestClose={_toggleModalVisible} />
            <SizedBox height={42} />
            <div style={{
                ...max360Width, display: "flex", flexDirection: "column",
                boxSizing: "border-box",
            }}>
                <img onClick={_onClickBackBtn} src={Bucket.leftArrow} className={enrollmentStyle["pointer"]}
                    style={{
                        width: "24px", height: "24px",
                        alignSelf: "flex-start", marginLeft: "20px"
                    }} />
            </div>
            <SizedBox height={196} />
            <div style={{
                position: "relative"
            }}>
                <div style={{
                    zIndex: -1,
                    width: "458px", height: "458px",
                    position: "absolute",
                    top: "50%", left: "50%", transform: "translate(-50%,-50%)",
                    backgroundImage: `url(${Bucket.top})`,
                    backgroundSize: "458px 458px",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    backgroundPositionY: "center",
                }} />
                <span style={{
                    display: "flex",
                    fontFamily: "Pretendard", fontSize: "20px", fontWeight: 700, color: "black",
                    whiteSpace: "pre-line", textAlign: "center",
                    zIndex: 2,
                }}>
                    {`사전등록\nFor Collector`}
                </span>
            </div>
            <SizedBox height={159} />
            <SexSelector value={sex} onClick={(sex) => {
                setSex(sex)
            }} style={{ ...max360Width }} />
            <SizedBox height={50} />
            <TextInput title={"이름"} value={name}
                placeholder="이름을 입력해주세요."
                onTextChange={(text) => {
                    setName(text)
                }}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={37} />
            <TextInput title={"전화번호"} value={phone}
                placeholder="ex.01012341234"
                onTextChange={(text) => {
                    setPhone(text)
                }}
                type="number"
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={37} />
            <TextInput title={"이메일"} value={email}
                placeholder="이메일을 입력해주세요."
                type="email"
                onTextChange={(text) => {
                    setEmail(text)
                }}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={59} />
            <DivideLine />
            <SizedBox height={50} />
            <RangeSelector title={"평소 예술에 대한 관심도를 알려주세요."}
                value={artPrefer}
                onClick={(value) => {
                    setArtPrefer(value)
                }}
                description={[
                    "전혀 관심 없다.",
                    "매우 관심 있다.",
                ]}
                items={[
                    1, 2, 3, 4, 5
                ]} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={age}
                onChange={(value) => {
                    setAge(value)
                }} title={"연령대를 알려주세요."}
                options={[
                    "10대",
                    "20세 - 27세",
                    "28세 - 32세",
                    "33세이상",
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={visitMuseum}
                onChange={(value) => {
                    setVisitMuseum(value)
                }} title={"미술관, 박물관, 전시회 등\n예술 관련 행사 연간 방문 횟수를 알려주세요"}
                options={[
                    "0회",
                    "1회",
                    "2 - 3회",
                    "4 - 6회",
                    "7회 이상",
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={maxPrice}
                onChange={(value) => {
                    setMaxPrice(value)
                }} title={"작가의 작품 한 점에\n최대 지출할 수 있는 금액을 알려주세요."}
                options={[
                    "0 - 20만원",
                    "20 - 50만원",
                    "50 - 100만원",
                    "100 - 500만원",
                    "500만원 이상",
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={preferPrice}
                onChange={(value) => {
                    setPreferPrice(value)
                }} title={"주로 어떤 금액대의 작품을\nKnowd를 통해서 관람하고 싶으신가요?"}
                options={[
                    "0 - 20만원",
                    "20 - 50만원",
                    "50 - 100만원",
                    "100 - 500만원",
                    "500만원 이상",
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={81} />
            <div style={{
                display: "flex", flexDirection: "row",
                alignItems: "center",
                gap: "44px"
            }}>
                <div style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center", gap: "12px",
                    fontFamily: "Pretendard",
                }}>
                    <span style={{
                        fontSize: "15px", fontWeight: 600
                    }}>{"개인정보 활용 동의"}</span>
                    <span onClick={_toggleModalVisible}
                        className={enrollmentStyle["pointer"]}
                        style={{
                            fontSize: "13px", fontWeight: 500,
                            color: "#838383"
                        }}>{"자세히 보기"}</span>
                </div>
                <img src={agree ? Bucket.checkBtn : Bucket.uncheckBtn}
                    className={enrollmentStyle["pointer"]}
                    style={{
                        width: "19px", height: "19px",
                    }}
                    onClick={_toggleAgree} />
            </div>
            <SizedBox height={235} />
            <div style={{
                display: "flex", flexDirection: "column", whiteSpace: "pre-line",
                textAlign: "center",
                position: "relative"
            }}>
                <div style={{
                    zIndex: -1,
                    width: "458px", height: "458px",
                    position: "absolute",
                    top: "50%", left: "50%", transform: "translate(-50%,-50%)",
                    backgroundImage: `url(${Bucket.collBottomGraphic})`,
                    backgroundSize: "458px 458px",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    backgroundPositionY: "center",
                }} />
                <span style={{
                    lineHeight: "140%",
                    fontFamily: "Pretendard", fontSize: "15px", fontWeight: 600
                }}>{"설문에 응해 주셔서 감사합니다!\n\n"}
                    <span style={{
                        color: "#941949"
                    }}>{"Knowd는 2023년 3월 중 \n구글 플레이와 앱스토어를 통해서\n만나보실 수 있습니다."}</span>
                </span>
            </div>
            <SizedBox height={189} />
            <img src={Bucket.submitBtn}
                className={enrollmentStyle["pointer"]}
                onClick={_onClickSubmitBtn}
                style={{
                    width: "82px", height: "40px"
                }} />
            <SizedBox height={88} />
            <img style={{
                zIndex: -1,
                width: "692px", height: "251px",
                position: "absolute",
                bottom: 0,
            }} src={Bucket.blur} />
        </div>
    )
}

export default CollectorEnrollment;