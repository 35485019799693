
//arrow function that phone number is valid
export const _isPhoneValid = (phone) => {
    //define phone number regex
    const phoneRegex = /^01(?:0|1|[6-9])(?:\d{3}|\d{4})\d{4}$/;
    //check if phone number is valid
    return phoneRegex.test(phone);
}
//arrow function that email is valid
export const _isEmailValid = (email) => {
    //define email regex
    const emailRegex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
    //check if email is valid
    return emailRegex.test(email);
}