import Bucket from "../../Enrollment/ImageUriContainer"
const HeadTitle = ({
    isTabletOrMobile
}) => {
    return (
        <div style={{
            paddingTop: isTabletOrMobile ? "252px" : "330px",
            paddingBottom: isTabletOrMobile ? "84px" : "330px",
        }}>
            {!isTabletOrMobile &&
                <div style={{
                    height: "1.6px", width: "55%", marginLeft: "auto",
                    background: "linear-gradient(to left, #940047FF, #94004700)"
                }} />}
            <div style={{
                padding: isTabletOrMobile ? "" :"76px 0px",
                display: "flex",
                alignItems: "center", justifyContent: "center",
            }}>
                <span style={{
                    fontFamily: "Butler", fontWeight: 400, color: "white",
                    fontSize: isTabletOrMobile ? "24px" : "72px",
                    position: "absolute", zIndex: 2
                }}>Public, encounters the Art
                </span>
                <picture>
                    <source srcSet={isTabletOrMobile ? `${Bucket.m_logo}` : `${Bucket.logo}` } type="image/webp"/>
                    <source srcSet={isTabletOrMobile ? Bucket.m_logo : Bucket.logo} type="image/png" />
                    <img src={isTabletOrMobile ? Bucket.m_logo: Bucket.logo } style={{
                        position: "relative",
                        width: isTabletOrMobile ? 98 : 306,
                        height: isTabletOrMobile ? 94 : 294,
                        zIndex: 1,
                    }} />
                </picture>
            </div>
            {!isTabletOrMobile &&
                <div style={{
                    height: "1.6px", width: "55%",
                    background: "linear-gradient(to right, #940047FF, #94004700)"
                }} />}
        </div>
    )
}

export default HeadTitle