import SizedBox from "./SizedBox";
import Bucket from "../ImageUriContainer"
import enrollmentStyle from "../Enrollment.css"

const RangeSelector = ({
    title,
    items,
    description = ["", ""],
    value,
    onClick
}) => {
    //if items is not array and lenght over than 2, return
    if (!Array.isArray(items) || items.length < 2) {
        return null;
    }

    return (
        <div style={{
            display: "flex", flexDirection: "column",
            //horizontal center
            alignItems: "center",
        }}>
            <span style={{
                fontFamily: "Pretendard", fontSize: "15px", fontWeight: 600
            }}>{title}</span>
            <SizedBox height={17} />
            <div style={{
                display: "flex", flexDirection: "row",
                gap: "56px", justifyContent: "center"
            }}>
                <span style={{
                    fontFamily: "Pretendard", fontSize: "12px", fontWeight: 500,
                    color: "#5F5F5F"
                }}>{`${items[0]} : ${description[0]}`}</span>
                <span style={{
                    fontFamily: "Pretendard", fontSize: "12px", fontWeight: 500,
                    color: "#5F5F5F"
                }}>{`${items[items.length - 1]} : ${description[1]}`}</span>
            </div>
            <SizedBox height={35} />
            <div style={{
                display: "flex", flexdirection: "row",
                gap: "47px", alignItems: "flex-end"
            }}>
                {items.map((item, index) => {
                    const isEdge = index === 0 || index === items.length - 1;
                    const valueIsEqual = value === item;
                    return (
                        <div key={`${item}_${index}`} style={{
                            display: "flex", flexDirection: "column",
                            alignItems: "center"
                        }}>
                            {isEdge &&
                                <span style={{
                                    marginBottom: "11px",
                                    fontFamily: "Pretendard", fontSize: "12px", fontWeight: 500,
                                }}>
                                    {item}
                                </span>}
                            <div className={enrollmentStyle["pointer"]}
                                style={{
                                    width: "14px", height: "14px",
                                    display: "flex", alignItems: "center", justifyContent: "center",
                                }} onClick={() => {
                                    onClick(item)
                                }}>
                                <img src={valueIsEqual ? Bucket.preferCheck : Bucket.preferUnCheck}
                                    style={{
                                        width: valueIsEqual ? "14px" : "10px", height: valueIsEqual ? "14px" : "10px",
                                    }} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default RangeSelector;