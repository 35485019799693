// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".W64znlvIrxaLTOx5ioj0::placeholder{\n    color : #A8A8A8;\n}\n\n.eVYTyaGYYhlJiwiAAGjs:hover{\n    cursor: pointer;\n}\ndiv.zpLzmHcTGJITElNo4Nci img.isauHALqzBbZzhFObXRa{\n    width : 234px; height: auto;\n    transition : width 0.3s, height 0.3s;\n}\ndiv.zpLzmHcTGJITElNo4Nci span.isauHALqzBbZzhFObXRa{\n    font-size: 24px;\n    transition-duration: 0.3s;\n}\ndiv.zpLzmHcTGJITElNo4Nci:hover img.isauHALqzBbZzhFObXRa{\n    width : 260px;\n}\ndiv.zpLzmHcTGJITElNo4Nci:hover span.isauHALqzBbZzhFObXRa{\n    font-size: 27px;\n}\n\na.Fh2x2iyfUYpBH22Xdqm5:link, a.Fh2x2iyfUYpBH22Xdqm5:visited {\n    color : #A8A8A8;\n    background-color: transparent;\n    text-decoration: none;\n}\nimg.Fh2x2iyfUYpBH22Xdqm5:hover {\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/Landing/Landing.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;AACA;IACI,aAAa,EAAE,YAAY;IAC3B,oCAAoC;AACxC;AACA;IACI,eAAe;IACf,yBAAyB;AAC7B;AACA;IACI,aAAa;AACjB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,6BAA6B;IAC7B,qBAAqB;AACzB;AACA;IACI,eAAe;AACnB","sourcesContent":[".form-input::placeholder{\n    color : #A8A8A8;\n}\n\n.send-button:hover{\n    cursor: pointer;\n}\ndiv.badge img.badge-trans{\n    width : 234px; height: auto;\n    transition : width 0.3s, height 0.3s;\n}\ndiv.badge span.badge-trans{\n    font-size: 24px;\n    transition-duration: 0.3s;\n}\ndiv.badge:hover img.badge-trans{\n    width : 260px;\n}\ndiv.badge:hover span.badge-trans{\n    font-size: 27px;\n}\n\na.s-archive-link:link, a.s-archive-link:visited {\n    color : #A8A8A8;\n    background-color: transparent;\n    text-decoration: none;\n}\nimg.s-archive-link:hover {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-input": "W64znlvIrxaLTOx5ioj0",
	"send-button": "eVYTyaGYYhlJiwiAAGjs",
	"badge": "zpLzmHcTGJITElNo4Nci",
	"badge-trans": "isauHALqzBbZzhFObXRa",
	"s-archive-link": "Fh2x2iyfUYpBH22Xdqm5"
};
export default ___CSS_LOADER_EXPORT___;
