 const bucket = {
    checkBtn : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/check_btn_x2.png",
    uncheckBtn : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/uncheck_btn_x2.png",
    artistBtmGraphic : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/artist_btm_graphic_x2.png",
    blur : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/bottom_blur_x2.png",
    leftArrow : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/back_x2.png",
    top : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/top_x2.png",
    submitBtn : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/submit_btn_x2.png",
    collBottomGraphic : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/collector/coll_btm_graphic_x2.png",
    close : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/close_x2.png",
    collectorBox : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/collector_box_x2.png",
    artistBox : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist_box_x2.png",
    full : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/full_x2.png",
    top_pre : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/top_pre_x2.png",
    bottom_pre : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/bottom_pre_x2.png",
    find : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/find_x2.png",
    preferCheck : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/collector/prefer_check_x2.png",
    preferUnCheck : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/collector/prefer_uncheck_x2.png",
    insta_check : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/check_sns_insta_x2.png",
    insta_uncheck : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/uncheck_sns_insta_x2.png",
    twitter_check : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/check_sns_tw_x2.png",
    twitter_uncheck : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/uncheck_sns_tw_x2.png",
    youtube_check : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/check_sns_yt_x2.png",
    youtube_uncheck : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/uncheck_sns_yt_x2.png",
    website_check : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/check_sns_web_x2.png",
    website_uncheck : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/enrollment/artist/uncheck_sns_web_x2.png",

    badgePlatform : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/badge_platform.png",
    badgeProject : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/badge_project.png",
    badgeStation : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/badge_station.png",
    m_badgePlatform : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_badge_platform.png",
    m_badgeProject : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_badge_project.png",
    m_badgeStation : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_badge_station.png",

    OutLine : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/value_outline.png",
    Bg : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/value_bg.png",
    Julian : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/value_julian.png",
    m_Julian : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_value_julian.png",
    Art : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/value_art.png",
    m_Art   : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_value_art.png",
    Shining : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/shining.png",
    m_Shining : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_shining.png",

    Line_tellus : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/line_tellus.png",
    mouse : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/mouse.png",
    m_mouse : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_mouse.png",
    Hand : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/hand.png",
    m_Hand : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_hand.png",
    KnowdSArchive : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/knowd_sarchive.png",
    m_KnowdSArchive : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_knowd_sarchive.png",
    TitleCoreValue : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/title_core_value.png",
    m_TitleCoreValue : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_title_core_value.png",

    logo : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/logo.png",
    m_logo : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/m_logo.png",

    btnJoin : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/btn_join.png",
    btnSend : "https://knowd-image-assets.s3.ap-northeast-2.amazonaws.com/btn_send.png",

}

export default bucket