import ReactModal, {Props} from "react-modal"
import pipp from "../../../asset/knowd_pre_PIPP.html"
import { useEffect } from "react"
import Bucket from "../ImageUriContainer"
import enrollmentStyle from "../Enrollment.css"

//JSDOc
/**
 * @param {Props} props
 * @returns {JSX.Element}
 * @constructor
 * @see https://www.npmjs.com/package/react-modal
 * @see https://www.npmjs.com/package/html-loader
 **/

const PippModal = ({...props}) => {
    useEffect(()=>{
        if(props.isOpen){
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "unset"
        }
    },[props.isOpen])
    return (
        <ReactModal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            ariaHideApp={false}
            style={{
                content : {
                    padding:"12px"
                }
            }}>
            <div style={{
                display: "flex", flexDirection: "column",
                alignItems: "flex-start",
                overflow: "hidden", position:"relative",
                height:"100%"
            }}>
                <img src={Bucket.leftArrow} 
                className={enrollmentStyle["pointer"]} 
                style={{
                    width:"24px",height:"24px"
                }}onClick={props.onRequestClose} />
                <div style={{
                    overflow: "auto"
                }} dangerouslySetInnerHTML={{
                    __html: pipp
                }} />
            </div>
        </ReactModal>
    )
}

export default PippModal