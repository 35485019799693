import { useEffect, Dispatch, SetStateAction, HTMLInputTypeAttribute} from 'react'
import landingStyle from '../Landing.css'

/**
 * @typedef {Object} InputBox
 * @property {string} title
 * @property {string} value
 * @property {string} placeholder
 * @property {HTMLInputTypeAttribute} type
 * @property {Dispatch<SetStateAction<string>>} [setValue]
 * @property {HTMLAttributes<HTMLDivElement>} [style]
 */

/**
 * @param {InputBox}
 * @returns 
 */
const InputBox = ({
    title, value, setValue,
    style, placeholder = "", type
}) => {
    return (
        <div style={{
            display: "flex", flexDirection: "column",
            ...style
        }}>
            <span style={{
                fontFamily: "SUIT", fontSize: "14px", fontWeight: 700, color: "#ECECEC",
                marginBottom: "11.52px"
            }}>{title}</span>
            <input className={landingStyle["form-input"]} style={{
                backgroundColor: "transparent",
                padding: "0px 0px 8.64px 0px",
                outline: "none",
                fontSize: "12px", fontFamily: "SUIT", color: "#ECECEC",
                borderWidth: "0px",
                borderBottomWidth: "1px", borderBottomStyle: "solid", borderBottomColor: "#ECECEC40",
            }} placeholder={placeholder}
                type={type} onChange={(e) => {
                    setValue(e.target.value)
                }} value={value} />
        </div>
    )
}

export default InputBox;