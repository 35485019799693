import SizedBox from "./SizedBox"
import Bucket from "../ImageUriContainer";

const SnsLink = ({ onChange, linkList, style,onClickIcon }) => {

    //name and iconImageUrl
    const IconList = [
        { name: "instagram", check: Bucket.insta_check, uncheck: Bucket.insta_uncheck },
        { name: "twitter", check: Bucket.twitter_check, uncheck: Bucket.twitter_uncheck },
        { name: "youtube", check: Bucket.youtube_check, uncheck: Bucket.youtube_uncheck },
        { name: "website", check: Bucket.website_check, uncheck: Bucket.website_uncheck },
    ]
    return (
        <div style={{
            display: "flex", flexDirection: "column", alignItems: "center",
            ...style
        }}>
            <span style={{
                fontFamily: "Pretendard", fontSize: "15px", fontWeight: 600,
                textAlign: "center", whiteSpace: "pre-line", lineHeight: "140%"
            }}>{"SNS나 웹사이트 등 작품활동의 기록을 남긴\n공간이 있다면 알려주세요."}</span>
            <SizedBox height={52} />
            <div style={{
                display: "flex", flexDirection: "row", gap: "24px"
            }}>
                {/* map */}
                {IconList.map((item, index) => {
                    return (
                        <img key={`${item.name}_icon_${index}`} src={linkList[index] !== undefined ? item.check : item.uncheck} alt={item.name}
                            style={{
                                width: "46px", height: "46px"
                            }} onClick={() => {
                                onClickIcon(index)
                            }} />
                    )
                })}
            </div>
            <SizedBox height={35} />
            {linkList.every((item) => item === undefined)
                ? null
                : <><div style={{
                    display: "flex", flexDirection: "column", gap: "24px", width: "100%", boxSizing: "border-box",
                    borderTop: "1px solid #B3B3B3",
                    padding: "50px 0 0 0 "
                }}>
                    {linkList.map((item, index) => {
                        if (item !== undefined) {
                            return (
                                <div key={`${index}_link_input_field`} style={{
                                    display: "flex", flexDirection: "row", alignItems: "center",
                                    gap: "9px", width: "100%", boxSizing: "border-box",
                                }}>
                                    <img style={{
                                        width: "46px", height: "46px"
                                    }} src={IconList[index].check} />
                                    <input type="text" placeholder={"링크를 첨부해주세요."} value={item}
                                        style={{
                                            flex: 1,
                                            border: "1px solid #B3B3B3", borderRadius: "20px",
                                            padding: "15px 19px"
                                        }}
                                        onChange={(e)=>{
                                            onChange(e.target.value,index)
                                        }} />
                                </div>
                            )
                        } else return null;
                    })}
                </div>
                    <SizedBox height={71} />
                </>
            }
        </div>
    )
}

export default SnsLink;

