import { useEffect, useState } from 'react'
import { useNavigate, UnSafe } from 'react-router-dom'
import Modal from 'react-modal'
import Bucket from './ImageUriContainer'
import enrollmentStyle from "./Enrollment.css"

const Enrollment = () => {
    useEffect(()=>{
        _scrollToTop();
    },[])
    
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState();
    const _onClickPreEnrollment = () => {
        console.log("Open Type Select Modal")
        _toggleModal();
    }
    const _scrollToTop = () => {
        window.scrollTo({
            top: 0,
        })
    }

    const _onClickFindBtn = () => {
        _scrollToTop();
        navigate("/landing")
    }

    const _toggleModal = () => {
        setIsModalVisible(!isModalVisible)
    }

    return (
        <div style={{
            backgroundColor: "white",
            maxHeight: "2956px"
        }}>
            <Modal
                isOpen={isModalVisible}
                onRequestClose={_toggleModal}
                shouldCloseOnEsc={true}
                shouldCloseOnOverlayClick={true}
                ariaHideApp={false}
                style={{
                    content: {
                        position: "absolute", top: "50%", left: "50%", right: "auto", bottom: "auto",
                        transform: "translate(-50%,-50%)",
                        display: "flex", padding: "none", border: "none"
                    }
                }}>
                <div style={{
                    backgroundColor: "rgba(0, 0, 0, 0.8)",
                    width: "327px", height: "210px",
                    borderRadius: "15px",
                    filter: "drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25))",
                    position: "relative",
                }}>
                    <span style={{
                        fontFamily: "Roboto",
                        fontWeight: 600, fontSize: "13px", color: "white",
                        position: "absolute",
                        top: `${18}px`, left: "50%", transform: "translate(-50%,0)"
                    }}>
                        {"Who are you?"}
                    </span>
                    <img src={Bucket.close} className={enrollmentStyle["pointer"]}
                        style={{
                            position: "absolute",
                            width: "24px", height: "24px",
                            top: "14px", left: "290px"
                        }}
                        onClick={_toggleModal} />
                    <div style={{
                        position: "absolute",
                        top: "50px", left: "22px",
                        display: "flex", flexDirection: "row",
                        alignItems: "center", gap: "13px", padding: "0px"
                    }}>
                        <div style={{
                            position: "relative",
                            display: "flex", flexDirection: "column",
                            alignItems: "center"
                        }} className={enrollmentStyle["pointer"]} 
                        onClick={() => {
                            _scrollToTop();
                            navigate("/collector")
                        }}>
                            <img src={Bucket.collectorBox}
                                style={{
                                    width: "135px", height: "135px"
                                }} />
                            <span style={{
                                fontFamily: "Pretendard",
                                fontSize: "13px", fontWeight: 600,
                                color: "#941949",
                                position: "absolute",
                                top: "23px",
                            }}>{"관람자입니다."}</span>
                        </div>
                        <div style={{
                            position: "relative",
                            display: "flex", flexDirection: "column",
                            alignItems: "center"
                        }} className={enrollmentStyle["pointer"]}
                             onClick={() => {
                            _scrollToTop();
                            navigate("/artist")
                        }}>
                            <img src={Bucket.artistBox}
                                style={{
                                    width: "135px", height: "135px"
                                }} />
                            <span style={{
                                fontFamily: "Pretendard",
                                fontSize: "13px", fontWeight: 600,
                                color: "#941949",
                                position: "absolute",
                                top: "23px",
                            }}>{"작가입니다."}</span>
                        </div>
                    </div>
                </div>
            </Modal>
            <div style={{
                maxWidth: "1312px",
                width: "100%", height: "2956px",
                margin: "auto",
                backgroundImage: `url(${Bucket.full})`,
                backgroundSize: "1312px 2956px",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                backgroundPositionY: "start",
                position: "relative",
                overflow: "hidden",
                display: "flex", flexDirection: "column",
                alignItems: "center"
            }}>
                <span style={{
                    position: "absolute",
                    left: "50%",
                    transform: "translate(-50%,0)",
                    top: "9.134%",
                    fontSize: "20px", fontWeight: 600, color: "black",
                    fontFamily: "Pretendard"
                }}>
                    대중예술 생태계
                </span>
                <img className={enrollmentStyle["pointer"]} onClick={_onClickPreEnrollment}
                    style={{
                        position: "absolute",
                        left: "50%",
                        transform: "translate(-50%,0)",
                        top: `calc(${579}/2956*100%)`,
                        width: "155px"
                    }} src={Bucket.top_pre} />
                <span style={{
                    position: "absolute",
                    top: `calc(${815}/2956*100%)`,
                    fontSize: "17px", fontWeight: 500, color: "black",
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    whiteSpace: "pre-line"
                }}>
                    {"예술,\n언제까지 보기만 할 건가?"}
                </span>
                <span style={{
                    position: "absolute",
                    top: `calc(${1195}/2956*100%)`,
                    fontSize: "18px", fontWeight: 700, color: "black",
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    whiteSpace: "pre-line"
                }}>
                    {"이제 자네도,\n집에 그림 하나는 걸어 놔야지"}
                </span>
                <span style={{
                    position: "absolute",
                    left: "calc(50% + 19px)",
                    top: `calc(${1457}/2956*100%)`,
                    fontSize: "15px", fontWeight: 600, color: "#838383",
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    whiteSpace: "pre-line"
                }}>
                    {"대중 예술 생태계를"}
                </span>

                <span style={{
                    position: "absolute",
                    right: "calc(50% + 3px)",
                    top: `calc(${1849}/2956*100%)`,
                    fontSize: "15px", fontWeight: 600, color: "#838383",
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    whiteSpace: "pre-line"
                }}>
                    {"모바일로 만나보세요."}
                </span>
                <span style={{
                    position: "absolute",
                    // left: "50%",
                    // transform: "translate(-50%,0)",
                    top: `calc(${2090}/2956*100%)`,
                    fontSize: "16px", fontWeight: 600, color: "#941949",
                    fontFamily: "Pretendard",
                    whiteSpace: "pre-line",
                    textAlign: "center",
                }}>
                    {"사전등록 아티스트, Knowd와\n협의하여 개인전 지원"}
                </span>
                <span style={{
                    position: "absolute",
                    // left: "50%",
                    // transform: "translate(-50%,0)",
                    top: `calc(${2293}/2956*100%)`,
                    fontSize: "16px", fontWeight: 600, color: "#941949",
                    fontFamily: "Pretendard",
                    whiteSpace: "pre-line",
                    textAlign: "center",
                }}>
                    {"사전등록 참여자 전원\nKnowd Art Fair 초대권 지급"}
                </span>
                <span style={{
                    position: "absolute",
                    top: `calc(${2496}/2956*100%)`,
                    fontSize: "20px", fontWeight: 700, color: "black",
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    whiteSpace: "pre-line"
                }}>
                    {"반가워요!"}
                </span>

                <img className={enrollmentStyle["pointer"]} onClick={_onClickPreEnrollment}
                    style={{
                        position: "absolute",
                        top: `calc(${2555}/2956*100%)`,
                        width: "130px"
                    }} src={Bucket.bottom_pre} />
                <span style={{
                    position: "absolute",
                    top: `calc(${2728}/2956*100%)`,
                    fontSize: "18px", fontWeight: 500, color: "black",
                    fontFamily: "Pretendard",
                    textAlign: "center",
                    whiteSpace: "pre-line"
                }}>
                    {"23년 3월 출시"}
                </span>
                <img className={enrollmentStyle["pointer"]} onClick={_onClickFindBtn}
                    style={{
                        position: "absolute",
                        top: `calc(${2822}/2956*100%)`,
                        width: "124px"
                    }} src={Bucket.find} />
            </div>
        </div>
    )
}

export default Enrollment