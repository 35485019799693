import Bucket from "../ImageUriContainer";
import enrollmentStyle from "../Enrollment.css"

const SexSelector = ({ value, onClick, style }) => {
    const genderList = [
        {
            korTitle: "남",
            value: "M"
        },
        {
            korTitle: "여",
            value: "F"
        }
    ]
    return (
        <div style={{
            ...style,
            display: "flex", flexDirection: "row",
            justifyContent: "center",
            gap: "114px"
        }}>
            {genderList.map((item, index) => {
                const { value: _value, korTitle } = item
                return <div key={`${_value}_${index}`} style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center",
                    gap: "13px"
                }}>
                    <span style={{
                        fontFamily: "Pretendard", fontSize: "15px", fontWeight: 700
                    }}>{korTitle}</span>
                    <img src={value === _value ? Bucket.checkBtn : Bucket.uncheckBtn}
                        className={enrollmentStyle["pointer"]}
                        style={{
                            width: "19px", height: "19px"
                        }}
                        onClick={() => {
                            console.log(_value)
                            onClick(_value)
                        }} />
                </div>
            })}
        </div>
    )
}

export default SexSelector