/**
 * @typedef {Object} SendDataOjbect
 * @property {string} type
 * @property {string} name
 * @property {string} affiliation
 * @property {string} call
 * @property {string} snsId
 * @property {string} comment
 * @returns 
 */

/**
 * @param {SendDataOjbect} sendData
 * @returns 
 */
export const sendData = async ({
    type, name, affiliation,
    call, snsId, comment
}) => {
    // if(!name || name.length < 1){
    //     return alert("이름을 입력해주세요.")
    // }
    const phoneRegex = /[0-9]{2,3}-[0-9]{3,4}-[0-9]{4}/i
    if (!phoneRegex.test(call)) {
        return alert("올바른 전화번호 형식으로 적어주세요 하이픈(-) 포함")
    }
    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    const raw = JSON.stringify({
        "type": type,
        "name": name,
        "affiliation": affiliation,
        "call": call,
        "snsId": snsId,
        "comment": comment
    })
    console.log(raw)

    const requestOptions = {
        method: "PUT",
        headers,
        body: raw,
        redirect: "follow"
    }

    try {
        const result = await fetch("https://api.knowd.art/v2/landing/recodeJoinerInfo", requestOptions)
        // const result = await fetch("https://eg1255avf2.execute-api.ap-northeast-2.amazonaws.com/prod", requestOptions)
        console.log(result)
        const jsonResult = await result.json()
        console.log(jsonResult)
        if(jsonResult.statusCode !== 200){
            console.log(jsonResult)
            throw Error(jsonResult.message + "statusCode is Not 200")
        }
        console.log(jsonResult)
        alert("전송되었습니다. 감사합니다.")
    } catch (error) {
        console.log(error)
        alert("정보 저장 중 오류 발생.")
    }
}