import React, { useState, useEffect } from 'react'
import { sendData } from '../functions/SendData';
import landingStyle from '../Landing.css'
import Bucket from '../../Enrollment/ImageUriContainer';
const FormBackgroundColor = "#FFFFFF07";
const subColor = "#ECECEC"

const KnowdForm = ({
    isTabletOrMobile,

}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [type, setType] = useState("ARTIST")
    const [name, setName] = useState("")
    const [affiliation, setAfiliation] = useState("")
    const [call, setCall] = useState("")
    const [snsId, setSnsId] = useState("")
    const [comment, setComment] = useState("")

    const JoinAndSend = ({
        onClickButton
    }) => {
        return (
            <div style={{
                display: "flex", flexDirection: "column",
                marginBottom: isTabletOrMobile ? "120px" : "132px"
            }}>
                <img className={landingStyle["send-button"]} src={isOpen ? Bucket.btnSend : Bucket.btnJoin}
                    style={{
                        alignSelf: "center",
                        width: isTabletOrMobile ? "156px" : "467px", height: "auto"
                    }}
                    onClick={onClickButton} />
                <div style={{
                    marginTop:"10px",
                    borderWidth: isTabletOrMobile ? "1px 0px 0px 0px" : "2px 0px 0px 0px", borderColor: "#FFFFFF30", borderStyle: "solid",
                    display:"flex",flexDirection:"column",gap:"16px",
                    fontFamily:"Pretendard",fontSize:"18px", fontWeight:500,alignItems:"center",
                    paddingTop:"32px"
                }}>
                    <span style={{
                        color:"#ABABAB"
                    }}>send 버튼을 누르시면 접수가 완료됩니다.</span>
                    <span style={{
                        color:"#F0F0F0"
                    }} >문의사항 | s.archive.official@gmail.com</span>
                </div>
            </div>
        )
    }
    return (
        <div>
            {isOpen &&
                /**FormData */
                <div style={{
                    // border: "1px solid black",
                    padding: isTabletOrMobile ? "24px 26px" : "72px 80px", backgroundColor: FormBackgroundColor,
                    fontFamily: "Butler", fontWeight: 500,
                    marginBottom: isTabletOrMobile ? "35px" : "106px",
                    display: "flex", flexDirection: "column", color: subColor
                }}>
                    <span style={{
                        fontSize: isTabletOrMobile ? "14px" : "40px",
                    }}>KNOWD와 함께 그림을 그릴 분들을 찾고 있습니다.</span>
                    <div style={{
                        borderWidth: isTabletOrMobile ? "0px 0px 1px 0px" : "0px 0px 3px 0px", borderStyle: "solid", borderColor: subColor,
                        marginTop: isTabletOrMobile ? "15px" : "56px", marginBottom: isTabletOrMobile ? "23px" : "89px"
                    }} />
                    <span style={{
                        fontSize: isTabletOrMobile ? "12px" : "36px",
                        marginBottom: isTabletOrMobile ? "10px" : "29px"
                    }}>Who Are You?</span>
                    <div style={{
                        display: "flex", flexDirection: "row", gap: isTabletOrMobile ? "7px" : "20px",
                        marginBottom: isTabletOrMobile ? "21px" : "64px",
                        fontSize: isTabletOrMobile ? "7px" : "20px",
                        fontFamily: "Butler"
                    }}>
                        <button style={{
                            display: "flex",
                            padding: isTabletOrMobile ? "5px 10px" : "15px 30px",
                            border: `${isTabletOrMobile ? "1px" : "2px"} solid ${subColor}`,
                            backgroundColor: type === "ARTIST" ? subColor : "transparent",
                            color: type === "ARTIST" ? "#434343" : subColor,
                            fontFamily: "inherit", fontSize: "inherit",
                        }} onClick={() => {
                            setType("ARTIST")
                        }}>
                            Artist
                        </button>
                        <button style={{
                            display: "flex",
                            padding: isTabletOrMobile ? "5px 10px" : "15px 30px",
                            border: `${isTabletOrMobile ? "1px" : "2px"} solid ${subColor}`,
                            backgroundColor: type === "GALLERY" ? subColor : "transparent",
                            color: type === "GALLERY" ? "#434343" : subColor,
                            fontFamily: "inherit", fontSize: "inherit",
                        }} onClick={() => {
                            setType("GALLERY")
                        }}>
                            Gallery
                        </button>
                    </div>
                    <span style={{
                        fontFamily: "SUIT", fontSize: isTabletOrMobile ? "12px" : "36px"
                    }}>성함</span>
                    <input className={landingStyle["form-input"]} style={{
                        backgroundColor: "transparent",
                        padding: isTabletOrMobile ? "7px 0px" : "22px 0px",
                        height: isTabletOrMobile ? "8px" : "24px",
                        maxWidth: isTabletOrMobile ? "188px" : "564px",
                        marginTop: isTabletOrMobile ? "6px" : "18px", outline: "none",
                        fontSize: isTabletOrMobile ? "8px" : "24px", fontFamily: "SUIT", color: subColor,
                        borderWidth: isTabletOrMobile ? "0px 0px 1px 0px" : "0px 0px 3px 0px", borderStyle: "solid", borderColor: subColor,
                        marginBottom: isTabletOrMobile ? "24px" : "72px"
                    }} type="text" placeholder="성함을 입력해주세요."
                    onChange={(e) => {
                        setName(e.target.value)
                    }} value={name} />
                    <span style={{
                        fontFamily: "SUIT", fontSize: isTabletOrMobile ? "12px" : "36px"

                    }}>소속</span>
                    <input className={landingStyle["form-input"]} style={{
                        backgroundColor: "transparent",
                        padding: isTabletOrMobile ? "7px 0px" : "22px 0px",
                        height: isTabletOrMobile ? "8px" : "24px",
                        maxWidth: isTabletOrMobile ? "188px" : "564px",
                        marginTop: isTabletOrMobile ? "6px" : "18px", outline: "none",
                        fontSize: isTabletOrMobile ? "8px" : "24px", fontFamily: "SUIT", color: subColor,
                        borderWidth: isTabletOrMobile ? "0px 0px 1px 0px" : "0px 0px 3px 0px", borderStyle: "solid", borderColor: subColor,
                        marginBottom: isTabletOrMobile ? "24px" : "72px"
                    }} type="text" placeholder="기관명을 입력해주세요." onChange={(e) => {
                        setAfiliation(e.target.value)
                    }} value={affiliation} />
                    <span style={{
                        fontFamily: "SUIT", fontSize: isTabletOrMobile ? "12px" : "36px"

                    }}>연락처*</span>
                    <input className={landingStyle["form-input"]} style={{
                        backgroundColor: "transparent",
                        padding: isTabletOrMobile ? "7px 0px" : "22px 0px",
                        height: isTabletOrMobile ? "8px" : "24px",
                        maxWidth: isTabletOrMobile ? "188px" : "564px",
                        marginTop: isTabletOrMobile ? "6px" : "18px", outline: "none",
                        fontSize: isTabletOrMobile ? "8px" : "24px", fontFamily: "SUIT", color: subColor,
                        borderWidth: isTabletOrMobile ? "0px 0px 1px 0px" : "0px 0px 3px 0px", borderStyle: "solid", borderColor: subColor,
                        marginBottom: isTabletOrMobile ? "24px" : "72px"
                    }} type="tel" onChange={(e) => {
                        setCall(e.target.value)
                    }} value={call} placeholder="010-0000-0000 형식으로 입력해주세요." />
                    <span style={{
                        fontFamily: "SUIT", fontSize: isTabletOrMobile ? "12px" : "36px"

                    }}>SNS 아이디</span>
                    <input className={landingStyle["form-input"]} style={{
                        backgroundColor: "transparent",
                        padding: isTabletOrMobile ? "7px 0px" : "22px 0px",
                        height: isTabletOrMobile ? "8px" : "24px",
                        maxWidth: isTabletOrMobile ? "188px" : "564px",
                        marginTop: isTabletOrMobile ? "6px" : "18px", outline: "none",
                        fontSize: isTabletOrMobile ? "8px" : "24px", fontFamily: "SUIT", color: subColor,
                        borderWidth: isTabletOrMobile ? "0px 0px 1px 0px" : "0px 0px 3px 0px", borderStyle: "solid", borderColor: subColor,
                        marginBottom: isTabletOrMobile ? "24px" : "72px"
                    }} onChange={(e) => {
                        setSnsId(e.target.value)
                    }} type="text" value={snsId} placeholder="링크 또는 아이디를 입력해주세요." />
                    <span style={{
                        fontFamily: "SUIT", fontSize: isTabletOrMobile ? "12px" : "36px"

                    }}>Comment</span>
                    <input className={landingStyle["form-input"]} style={{
                        backgroundColor: "transparent",
                        padding: isTabletOrMobile ? "7px 0px" : "22px 0px",
                        height: isTabletOrMobile ? "8px" : "24px",
                        maxWidth: isTabletOrMobile ? "188px" : "564px",
                        marginTop: isTabletOrMobile ? "6px" : "18px", outline: "none",
                        fontSize: isTabletOrMobile ? "8px" : "24px", fontFamily: "SUIT", color: subColor,
                        borderWidth: isTabletOrMobile ? "0px 0px 1px 0px" : "0px 0px 3px 0px", borderStyle: "solid", borderColor: subColor,
                        marginBottom: isTabletOrMobile ? "24px" : "72px"
                    }} type="text" onChange={(e) => {
                        setComment(e.target.value)
                    }} value={comment} placeholder="추가적인 의견이 있으시면 입력해주세요." />
                </div>}
            <JoinAndSend onClickButton={async() => {
                if (isOpen) {
                    await sendData({
                        type,name,affiliation,call,snsId,comment
                    })
                } else setIsOpen(!isOpen)
            }} />
        </div>
    )
}

export default KnowdForm