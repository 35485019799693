import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { sendData } from '../functions/SendData'
import InputBox from './InputBox'

const Send = () => {
    const navigate = useNavigate()
    const [type, setType] = useState("ARTIST")
    const [name, setName] = useState("")
    const [affiliation, setAfiliation] = useState("")
    const [call, setCall] = useState("")
    const [snsId, setSnsId] = useState("")
    const [comment, setComment] = useState("")

    useEffect(()=>{
        window.scrollTo({
            top:0,left:0,behavior:"auto"
        })
    },[])
    const SendButton = () => {
        return (
            <div style={{
                position: "fixed", zIndex: 5,
                backgroundColor: "#832954",
                bottom: 0, right: 0, left: 0,
                padding: "21px 150px",
                fontFamily: "Butler", textAlign: "center", color: "white", fontWeight: 700
            }} onClick={async () => {
                    console.log({
                        type, name, affiliation, call, snsId, comment})
                    await sendData({
                        type, name, affiliation, call, snsId, comment
                    })
                navigate("/")
            }}>
                <span>
                    Send
                </span>
            </div>
        )
    }

    return (
        <div style={{
            border: "1px solid black",
            boxSizing: "border-box",
            minHeight: "100vh",
            padding: "64px 20px calc(64px + 35px) 20px",
            display: "flex", flexDirection: "column", alignItems: "center",
            background: "linear-gradient(180deg, #040404 0%, #1B1B1B 13.7%, #3C3C3C 100%)"
        }}>
            <span style={{
                fontFamily: "Butler", fontSize: "20px", fontWeight: 500, color: "white",
                marginBottom: "22px"
            }}>Join Us</span>
            <div style={{
                background: "#434343", boxSizing: "border-box",
                borderRadius: "8px", padding: "32px 24px",
                marginBottom: "16px",
                width: "100%",
                display: 'flex', flexDirection: "column"
            }}>
                <div style={{
                    borderBottom: "1px solid #838383",
                    paddingBottom: "18px", marginBottom: "22px"
                }}>
                    <span style={{
                        fontFamily: "Butler", fontWeight: 500, fontSize: "15px", color: "#ECECEC",
                        whiteSpace: "pre-line"
                    }}>
                        {"KNOWD와 함께\n그림을 그릴 분들을 찾고 있습니다."}
                    </span>
                </div>
                <div style={{
                    display: "flex", flexDirection: "column", gap: "12px"
                }}>
                    <span style={{
                        fontFamily: "Butler", fontWeight: 500, fontSize: "16px", color: "#ECECEC"
                    }}>
                        {"Who Are You?"}
                    </span>
                    <div style={{
                        display: "flex", flexDirection: "row", gap: "8px"
                    }}>
                        <button style={{
                            display: "flex",
                            padding: "12px 24px",
                            border: `1px solid ${type === "ARTIST" ? "#832954" : "#ECECEC"}`,
                            backgroundColor: type === "ARTIST" ? "#832954" : "transparent",
                            color: type === "ARTIST" ? "#FFFFFF" : "#ECECEC",
                            fontFamily: "Butler", fontSize: "14px",
                        }} onClick={() => {
                            setType("ARTIST")
                        }}>
                            Artist
                        </button>
                        <button style={{
                            display: "flex",
                            padding: "12px 24px",
                            border: `1px solid ${type === "GALLERY" ? "#832954" : "#ECECEC"}`,
                            backgroundColor: type === "GALLERY" ? "#832954" : "transparent",
                            color: type === "GALLERY" ? "#FFFFFF" : "#ECECEC",
                            fontFamily: "Butler", fontSize: "14px",
                        }} onClick={() => {
                            setType("GALLERY")
                        }}>
                            Gallery
                        </button>
                    </div>
                </div>
                <InputBox title="성함"
                    value={name} setValue={setName}
                    placeholder="성함을 입력해주세요." type="text"
                    style={{
                        marginTop:"34px",
                        marginBottom: "32px",
                    }} />
                <InputBox title="소속"
                    value={affiliation} setValue={setAfiliation}
                    placeholder="기관명을 입력해주세요." type="text"
                    style={{
                        marginBottom: "32px",
                    }} />
                <InputBox title="연락처*"
                    value={call} setValue={setCall}
                    placeholder="010-0000-0000 형식으로 입력해주세요." type="tel"
                    style={{
                        marginBottom: "32px"
                    }} />
                <InputBox title="SNS 아이디"
                    value={snsId} setValue={setSnsId}
                    placeholder="링크 또는 아이디를 입력해주세요." type="text"
                    style={{
                        marginBottom: "32px"
                    }} />
                <InputBox title="Comment"
                    value={comment} setValue={setComment}
                    placeholder="추가적인 의견이 있으시면 입력해주세요." type="text" />
            </div>
            <span style={{
                fontFamily: "Pretendard", fontWeight: 500, fontSize: "12px", color: "#ABABAB"
            }}>
                문의사항 | s.archive.official@gmail.com
            </span>
            <SendButton />
        </div>
    )
}

export default Send