const DivideLine = ({
    style
}) => {
    return (
        <div style={{
            width:"100%", maxWidth:"254px",
            border:"2px solid #941949",
            ...style,
        }}/>
    )
}

export default DivideLine