import { StrictMode, useEffect } from "react"
import { createBrowserRouter, Navigate, redirect, RouterProvider } from "react-router-dom"
import ArtistEnrollment from "./pages/Enrollment/artist"
import CollectorEnrollment from "./pages/Enrollment/Collector"
import Enrollment from "./pages/Enrollment/Enrollment"
import Send from "./pages/Landing/components/Send"
import Landing from "./pages/Landing/Landing"
import './stylesheet.css'
const App = () => {

    const router = createBrowserRouter([
        {
            path : "/",
            element : (
                <Navigate to="/enrollment" replace/>
                // <div>
                //     Main
                // </div>
            ),
            // loader : async() => {
            //     console.log("Redirect to '/'")
            //     throw redirect("/landing",{
            //     })
            // }
            
        },
        {
            path : "/landing",
            element : <Landing/>
        },
        {
            path: "/send",
            element : <Send/>
        },
        {
            path : "/enrollment",
            element : <Enrollment/>,
        },
        {
            path : "/collector",
            element : <CollectorEnrollment/>
        },
        {
            path : "/artist",
            element : <ArtistEnrollment/>
        },
    ])

    return (
        <StrictMode>
            <RouterProvider router={router}/>
        </StrictMode>
    )
}

export default App;