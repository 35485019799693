import { useState, useLayoutEffect, useRef, HTMLAttributes } from 'react'

import landingStyle from '../Landing.css'
import Bucket from '../../Enrollment/ImageUriContainer';

/**
 * 
 * @param {HTMLAttributes<HTMLDivElement>} style 
 * @returns 
 */
const CoreValue = ({
    isTabletOrMobile,
    style
}) => {
    const contentsRef = useRef();
    const [contentsDimensions, setContentsDimensions] = useState({
        width: 0, height: 0
    })

    const handRef = useRef();
    const [handDimension, setHandDimension] = useState({
        w: 0, h: 0
    })

    const goToSArchive = () => {
        window.open("https://s-archive.net","_blank")
    }
    return (
        <div style={{
            display: "flex", flexDirection: "column",
            alignItems: "center",
            ...style
        }}>
            {!isTabletOrMobile ?
                <div style={{
                    backgroundImage: `url(${Bucket.OutLine})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: 'center',
                    width: "100%", aspectRatio: 1.18677 / 1,
                    position: "relative", marginBottom: "90px"
                }}>
                    <img src={Bucket.Bg} style={{
                        position: "absolute",
                        height: "68.63%",
                        bottom: "8.04%",
                        left: "22.82%"
                    }} />
                    <img src={Bucket.TitleCoreValue}
                        style={{
                            position: "absolute",
                            height: "11.53%",
                            top: "10.2%",
                            right: "50%", transform: "translate(50%, 0px)"
                        }} />
                    <div style={{
                        position: "absolute",
                        left: "14.16%", top: "34.2%",
                        height: "17.87%",
                        display: "flex"
                    }}>
                        <img src={Bucket.Julian} style={{
                            height: "100%"
                        }} />
                        <div style={{
                            marginLeft: "21px",
                            display: "flex", flexDirection: "column", gap: "16px",
                            color: "white", wordBreak: "keep-all"
                        }}>
                            <span style={{
                                fontSize: "24px", lineHeight: "24px",
                                fontFamily: "sans-serif", fontWeight: 700
                            }}>Public Art as a Public Art</span>
                            <span style={{
                                fontSize: "16px", lineHeight: "131.19%",
                                fontFamily: "Pretendard", fontWeight: 500,
                                color: "#F0F0F0", whiteSpace: "pre-line"
                            }}>{"대중예술을 온전히 대중들이 영위할 수 있는 세상,\n"
                                + "인류가 추구하는 “아름다움”이라는 본연의 가치를\n"
                                + "대중들과 공유하는 세상을 만듭니다."}</span>
                        </div>
                    </div>
                    <img src={Bucket.Shining}
                        style={{
                            position: "absolute",
                            height: "22.08%",
                            bottom: "25.01%",
                            left: "27.64%"
                        }} />
                    <div ref={contentsRef} style={{
                        position: "absolute",
                        height: "13.58%",
                        bottom: "21.09%", left: "51.58%", right: 0,
                        display: "flex",
                    }}>
                        <img src={Bucket.Art}
                            onLoad={(e) => {
                                console.log("Load Art Image")
                                if (e.currentTarget) {
                                    console.log(e.currentTarget.offsetWidth)
                                    setContentsDimensions({
                                        width: e.currentTarget.offsetWidth,
                                        height: e.currentTarget.offsetHeight
                                    })
                                }
                            }}
                            style={{
                                height: "100%"
                            }} />
                        <div style={{
                            position: "absolute",
                            display: "flex", flexDirection: "column", gap: "16px",
                            color: "white", wordBreak: "keep-all",
                            left: `calc(${contentsDimensions.width}px * 0.8)`, bottom: 0,
                        }}>
                            <span style={{
                                fontSize: "24px", lineHeight: "24px",
                                fontFamily: "sans-serif", fontWeight: 700
                            }}>Artists as Artists</span>
                            <span style={{
                                fontSize: "16px", lineHeight: "131.19%",
                                fontFamily: "Pretendard", fontWeight: 400,
                                color: "#F0F0F0", whiteSpace: "pre-line"
                            }}>{"작가분들을 온전히 작가로서, "}
                                <span style={{
                                    fontWeight: 500, textDecoration: "underline"
                                }}>{"작가님의 붓"}</span>
                                {"에는\n"
                                    + "깊은 사색으로부터 나온 고결한 철학을 담으세요.\n"}

                                <span style={{
                                    fontWeight: 500, textDecoration: "underline"
                                }}>{"우리의 붓"}</span>
                                {"에는 작가님의 철학을 대중들에게 조금 \n"
                                    + "더 재미있게 설명하는 대본을 담겠습니다."}
                            </span>
                        </div>
                    </div>
                </div>
                :
                <div style={{
                    width: "100%",
                    display: "flex", flexDirection: "column",
                    alignItems: "center",
                    marginBottom:"48px"
                }}>
                    <div style={{
                        position: "relative",
                        display: "flex", flexDirection: "column",
                        alignItems: "center", zIndex: 1
                    }}>
                        <img src={Bucket.m_TitleCoreValue}
                            style={{
                                width: "186px",
                            }} />
                        <img src={Bucket.Bg} style={{
                            position: "absolute",
                            width: "222.42px", height: "276.86px",
                            top: "calc(100% + 54px)",
                        }} />
                    </div>
                    <div style={{
                        marginTop: "46px",
                        zIndex: 2
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "column"
                        }}>
                            <img src={Bucket.m_Julian} style={{
                                width: "78px", height: "72.px",
                                marginBottom: "15.24px"
                            }} />
                            <div style={{
                                marginTop: "15.24px",
                                display: "flex", flexDirection: "column", gap: "8px",
                                color: "white", wordBreak: "keep-all"
                            }}>
                                <span style={{
                                    fontSize: "14px", lineHeight: "16px",
                                    fontFamily: "sans-serif", fontWeight: 700
                                }}>Public Art as a Public Art</span>
                                <span style={{
                                    fontSize: "12px", lineHeight: "140%",
                                    fontFamily: "Pretendard", fontWeight: 500,
                                    color: "#ABABAB", whiteSpace: "pre-line"
                                }}>{"대중예술을 온전히 대중들이 영위할 수 있는 세상,\n"
                                    + "인류가 추구하는 “아름다움”이라는 본연의 가치를\n"
                                    + "대중들과 공유하는 세상을 만듭니다."}</span>
                            </div>
                        </div>
                        <img src={Bucket.m_Shining}
                            style={{
                                marginLeft: "33px", marginTop: "8.66px",marginBottom:"9px",
                                width: "114px", height: "134px"
                            }} />
                        <div style={{
                            position: "relative",
                            marginLeft: "55px",
                            marginTop: "29.38px",
                            display: "flex", flexDirection: "column"
                        }}>
                            <img src={Bucket.m_Art}
                                style={{
                                    position: "absolute", zIndex: 1,
                                    width: "56px", height: "74px",
                                    left: "-32px", top: "-29px"
                                }} />
                            <div style={{
                                zIndex: 2, display: "flex", flexDirection: "column", gap: "8px",
                                wordBreak: "keep-all", color: "#ABABAB"
                            }}>
                                <span style={{
                                    fontSize: "14px", lineHeight: "16px",
                                    fontFamily: "sans-serif", fontWeight: 700,
                                    color: "#F0F0F0"
                                }}>Artists as Artists</span>
                                <span style={{
                                    fontSize: "12px", lineHeight: "140%",
                                    fontFamily: "Pretendard", fontWeight: 400,
                                    whiteSpace: "pre-line"
                                }}>{"작가분들을 온전히 작가로서, "}
                                    <span style={{
                                        fontWeight: 500, textDecoration: "underline",
                                        color: "#F0F0F0"
                                    }}>{"작가님의 붓"}</span>
                                    {"에는\n"
                                        + "깊은 사색으로부터 나온 고결한 철학을 담으세요.\n"}

                                    <span style={{
                                        fontWeight: 500, textDecoration: "underline",
                                        color: "#F0F0F0"
                                    }}>{"우리의 붓"}</span>
                                    {"에는 작가님의 철학을 대중들에게 조금 \n"
                                        + "더 재미있게 설명하는 대본을 담겠습니다."}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div style={{
                display: "flex", flexDirection: "column",
                alignItems: "flex-start",
                marginBottom: isTabletOrMobile ? "93px" : "197px",
            }}>
                <div style={{
                    background: "#FFFFFF07",
                    padding: isTabletOrMobile ? "10px 16px" :"15px 23px",
                    fontFamily: "Pretendard",
                    fontSize: isTabletOrMobile ? "12px" :"16px", wordBreak: "keep-all",
                    whiteSpace: "pre-line",
                    maxWidth: "480px",
                    color: "#FFFFFF50", lineHeight: "136%",
                }}>{`타흐가 불가리아에서 자주 별을 보았다고 하던 말이 생각나서, 나도 그때부터 밤마다 하늘을 바라보기로 결심했다. (반짝이는 빛들 중 어느 하나에서 정말 우리의 시선이 서로 만날 수도 있잖은가.) 
                - "볼레스와프 프루스 - 인형 中"

                여러분들과 우리의 시선은 예술에서 만날 수 있다고 Knowd는 확신합니다.`}
                </div>
                {!isTabletOrMobile && <div style={{
                    display: "flex", flexDirection: "column",
                    alignItems: "flex-end",
                    marginTop: "44px",
                    position: "relative",
                    paddingRight: handDimension.w / 2,
                }}>
                    <img src={Bucket.Hand} style={{
                        position: "absolute", width: "398px",
                        transform: "translate(50%,-50%)"
                    }} onLoad={(e) => {
                        if (e.currentTarget) {
                            const { offsetHeight, offsetWidth } = e.currentTarget
                            setHandDimension({
                                w: offsetWidth, h: offsetHeight
                            })
                        }
                    }} />
                    <span style={{
                        fontSize: "32px", fontFamily: "Butler", lineHeight: "136%", color: "#838383"
                    }}>Tell us about your art, Knowd will respond</span>
                    <img src={Bucket.Line_tellus} style={{
                        width: "740px"
                    }} />
                </div>}
            </div>
            <div style={{ display:"flex", flexDirection:"column"}}>
                <div style={{
                    display: "flex", flexDirection: isTabletOrMobile ? "column" : "row",
                    alignItems: "center",
                }}>
                    <img src={isTabletOrMobile ? Bucket.m_KnowdSArchive  : Bucket.KnowdSArchive }
                        style={{
                            width: isTabletOrMobile ? "134px" : "281px",
                            marginRight: isTabletOrMobile ? undefined : "19px",
                            marginBottom: isTabletOrMobile ? "28px" : undefined
                        }} />
                    <span style={{
                        whiteSpace: "pre-line"
                    }}>
                        <span style={{
                            fontFamily: "Butler", color: "#F0F0F0",
                            fontSize: isTabletOrMobile ? "14px" : "28px", fontWeight: 500,
                            marginBottom: isTabletOrMobile ? "16px" : "24px"
                        }}>{"We construct the finest\n\n"}</span>
                        <span style={{
                            fontFamily: "Pretendard", fontWeight: 500,
                            fontSize: isTabletOrMobile ? "12px" : "16px", color: isTabletOrMobile ? "#FFFFFF60" : "#ABABAB"
                        }}>
                            {"우리는 많은 사람들과 많은 시간동안 많은 플랫폼 비즈니스를 제작해왔습니다.\n\n"
                                + "우리는 조사했고, 기획하였고, 디자인하였고, 개발하였습니다.\n"
                                + "나아가 시장에 멋지게 전시했습니다.\n\n"
                                + "우리가 지나온 발자취가 궁금하다면,\n"
                                + "더 알아보시는 것을 주저하지 마세요."}
                        </span>
                    </span>
                    {isTabletOrMobile &&
                        <div style={{
                            display: "flex", flexDirection: "row",
                            marginTop: "30px",
                            alignItems: "center", alignSelf: "flex-end",
                            fontSize: "14px", fontFamily: "sans-serif"
                        }}>
                            <a className={landingStyle['s-archive-link']} href="https://s-archive.net" target="_blank"
                                style={{
                                    transform: "translate(0, 50%)"
                                }}>https://s-archive.net</a>
                            <img className={landingStyle['s-archive-link']} src={Bucket.m_mouse } width="38px" height="53px"
                                onClick={goToSArchive} />
                        </div>}
                    {isTabletOrMobile &&
                        <div style={{
                            display: "flex", flexDirection: "column",
                            alignItems: "center", gap: "20px"
                        }}>
                            <img src={Bucket.m_Hand } style={{
                                marginTop: "75px",
                                width: "136px",
                            }} />
                            <span style={{
                                fontSize: "14px", fontFamily: "Butler",
                                color: "#FFFFFF40"
                            }}>
                                Tell us about your art, Knowd will respond.
                            </span>
                        </div>
                    }
                </div>
                {!isTabletOrMobile &&
                    <div style={{
                        display: "flex", flexDirection: "row",
                        marginTop: "30px",
                        alignItems: "center", alignSelf: "flex-end",
                        fontSize: "20px", fontFamily: "sans-serif"
                    }}>
                        <a className={landingStyle['s-archive-link']} href="https://s-archive.net" target="_blank"
                            style={{
                                transform: "translate(0, 50%)"
                            }}>https://s-archive.net</a>
                        <img className={landingStyle['s-archive-link']} src={Bucket.mouse } width="70px" height="97px"
                            onClick={goToSArchive} />
                    </div>}
            </div>
        </div>
    )
}

export default CoreValue