export const sendEnrollment = async (data,type) => {
    const headers = new Headers();
    headers.append("Content-Type", "application/json")
    const raw = JSON.stringify(data)
    console.log(raw)

    const requestOptions = {
        method: "PUT",
        headers,
        body: raw,
        redirect: "follow"
    }
    const result = await fetch(`https://api.knowd.art/v2/enrollment/${type}`, requestOptions)
    console.log(result)
    const jsonResult = await result.json()
    console.log(jsonResult)
    if (jsonResult.statusCode !== 200) {

        throw Error(jsonResult.message + "statusCode is Not 200")
    }
    console.log(jsonResult)
}
