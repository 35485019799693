import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'

import AboutKnowd from './components/AboutKnowd'
import CoreValue from './components/CoreValue'
import HeadTitle from './components/HeadTitle'
import KnowdForm from './components/KnowdForm'

import "./Landing.css"

const Landing = () => {

    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const navigate = useNavigate();

    useEffect(() => {
        var host = window.location.host
        var isMobileDomain = host.split(".")[0] === "m"
        // if(isTabletOrMobile && !isMobileDomain){
        //     window.open("https://m.knowd.art/landing","_parent")
        // }else if (!isTabletOrMobile && isMobileDomain) {
        //     window.open("https://knowd.art/landing","_parent")
        // }
    }, [isTabletOrMobile])

    return (
        <div style={{
            background: "linear-gradient(to bottom, black, #3B3B3B, #3B3B3B)",
            // display:"flex", flexDirection:"column",alignItems:"center",
        }}>
            <HeadTitle isTabletOrMobile={isTabletOrMobile} />
            {/* Vertical Line Only Mobile */}
            {isTabletOrMobile &&
                <div style={{
                    height: "213px", width: "2px",
                    background: "linear-gradient(to bottom, #1A1A1A, #D1D1D1)",
                    margin: "auto", marginBottom: "33px"
                }} />}
            <div style={{
                maxWidth: isTabletOrMobile ? "360px" : "1280px",
                marginRight: "auto", marginLeft: "auto",
                display: "flex", flexDirection: "column",
            }}>
                <AboutKnowd isTabletOrMobile={isTabletOrMobile} />
                <CoreValue isTabletOrMobile={isTabletOrMobile} style={{
                    marginBottom: isTabletOrMobile ? "79px" : "207px"
                }} />
                {!isTabletOrMobile &&
                    <KnowdForm isTabletOrMobile={isTabletOrMobile} />
                }
            </div>
            {isTabletOrMobile &&
                <div style={{
                    position: "fixed", zIndex: 5,
                    backgroundColor: "#832954",
                    bottom: 0, right: 0, left: 0,
                    padding: "21px 150px",
                    fontFamily: "Butler", textAlign: "center", color: "white", fontWeight: 700
                }} onClick={() => {
                    navigate("/send")
                }}>
                    <span>
                        Join Us
                    </span>
                </div>}
            <div style={{ 
                background: "#434343", boxSizing: "border-box",
                display:"flex",
                paddingBottom: isTabletOrMobile ? "64px" : "0px",
            }}>
                <div style={{
                    flex: 1,
                    margin: `${isTabletOrMobile ? "20px" : "44px"} auto`,
                    maxWidth: isTabletOrMobile ? "360px" : "1280px",
                    fontFamily: "Pretendard", fontWeight: 400,
                    fontSize: isTabletOrMobile ? "12px" : "20px",
                    color: "#A8A8A8", whiteSpace: "pre-line",
                    lineHeight: "160%",
                    display: "flex", flexDirection: "column", gap: isTabletOrMobile ? "2px" : "4px"
                }}>
                    <span>{"상호 | (주) 에스아카이브 / S-Archive. corp."}</span>
                    <span>{"대표 | 선우건영"}</span>
                    <span>{"주소 | 서울 동대문구 답십리로 38길 19, B동 201호"}</span>
                    <span>{"개인정보관리 책임자 | 선우건영 (seonwoo@s-archive.net)"}</span>
                    <span>{"사업자등록번호 | 457-88-02635"}</span>
                    <span>{"이메일 문의 |  s.archive.official@gmail.com"}</span>
                </div>
            </div>
        </div>
    )
}


export default Landing;