import { useState, useLayoutEffect, useRef } from 'react'
import landingStyle from '../Landing.css'
import Bucket from '../../Enrollment/ImageUriContainer';

const AboutKnowd = ({
    isTabletOrMobile
}) => {

    const [hoverTarget, setHoverTarget] = useState("");
    const contentsRef = useRef();
    const [contentsDimensions, setContentsDimensions] = useState({
        width: 0, height: 0
    })

    useLayoutEffect(() => {
        if (contentsRef.current) {
            setContentsDimensions({
                width: contentsRef.current.offsetWidth,
                height: contentsRef.current.offsetHeight
            })
        }
    }, [])

    return (
        <div style={{
            display: "flex", flexDirection: "column",
            marginBottom: isTabletOrMobile ? "73px" : "45px",
        }}>
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center"
            }}>
                <span style={{
                    color: "white", fontFamily: "Butler", fontWeight: "500", fontSize: isTabletOrMobile ? "22px" : "54px",
                    marginBottom: isTabletOrMobile ? "8px" : "12px"
                }}>
                    KNOWD
                </span>
                <div style={{
                    backgroundColor: "#282828", color: "#F0F0F0",
                    borderRadius: isTabletOrMobile ? "20.63px" : "46px", padding: isTabletOrMobile ? "3.59px 14.35px" : "8px 32px",
                    fontSize: isTabletOrMobile ? "12px" : "20px",
                    marginBottom: isTabletOrMobile ? "62.83px" : "77px"
                }}>
                    <span>ecosystem</span>
                </div>
            </div>
            <div style={{
                display: "flex", flexDirection: "column"
            }}>
                {!isTabletOrMobile
                    ?
                    <div style={{
                        height: 400,
                        boxSizing: "border-box",
                        border: "2px solid #5A5A5A", borderRadius: "250px",
                        position: "relative",
                    }}>
                        <div style={{
                            display: "flex", flexDirection: "row",
                            justifyContent: "space-between",
                            padding: "67px 214px 63px 214px",
                            position: "absolute",
                            left: 0, right: 0, top: 0,
                            boxSizing: "border-box"
                        }}>
                            <div className={landingStyle["badge"]} style={{
                                position: "relative"
                            }}
                                onMouseEnter={() => {
                                    console.log("onMouseEnter")
                                    setHoverTarget("PLATFORM")
                                }}
                                onMouseLeave={() => {
                                    console.log("onMouseLeave")
                                    setHoverTarget("")
                                }}>
                                <span className={landingStyle["badge-trans"]} style={{
                                    textAlign: "center",
                                    display: "block", color: hoverTarget === "PLATFORM" ? "#D44489" : "white",
                                    fontFamily: "sans-serif", marginBottom: "42px"
                                }}>
                                    KNOWD<br />
                                    Platform
                                </span>
                                <img className={landingStyle["badge-trans"]} src={Bucket.badgePlatform} />
                                {
                                    hoverTarget === "PLATFORM"
                                    &&
                                    <div style={{
                                        position: "absolute",
                                        left: "50%", transform: "translate(-50%, 33px)",
                                        width: "100%", textAlign: "center"
                                    }}>
                                        <div style={{
                                            position: "relative",
                                            margin: "auto",
                                            width: "0px", height: "0px",
                                            borderWidth: "0px 8px 13px 8px",
                                            borderStyle: "solid", borderColor: "transparent transparent #444444 transparent "
                                        }}>
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            left: "calc(50% - 30%)",
                                            background: "#444444", padding: "15px 32px",
                                            borderRadius: "41px", color: "#FFFFFF50",
                                            display: "flex", wordBreak: "keep-all", minWidth: "320px",
                                            boxSizing: "border-box"
                                        }}>
                                            <span style={{
                                                fontSize: "16px", fontFamily: "Pretendard", lineHeight: "160%"
                                            }}>온라인 전시 및 오프라인 전시 정보 열람 작가들이 제작하는 다양한 아트 컨텐츠</span>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={landingStyle["badge"]} style={{
                                position: "relative"
                            }} onMouseEnter={() => {
                                console.log("onMouseEnter")
                                setHoverTarget("PROJECT")
                            }}
                                onMouseLeave={() => {
                                    console.log("onMouseLeave")
                                    setHoverTarget("")
                                }}>
                                <span className={landingStyle["badge-trans"]} style={{
                                    textAlign: "center",
                                    display: "block", color: hoverTarget === "PROJECT" ? "#D44489" : "white",
                                    fontFamily: "sans-serif", marginBottom: "42px"
                                }}>
                                    KNOWD<br />
                                    Project
                                </span>
                                <img className={landingStyle["badge-trans"]} src={Bucket.badgeProject } />
                                {
                                    hoverTarget === "PROJECT"
                                    &&
                                    <div style={{
                                        position: "absolute",
                                        left: "50%", transform: "translate(-50%, 33px)",
                                        width: "100%", textAlign: "center",
                                    }}>
                                        <div style={{
                                            margin: "auto",
                                            width: "0px", height: "0px",
                                            borderWidth: "0px 8px 13px 8px",
                                            borderStyle: "solid", borderColor: "transparent transparent #444444 transparent "
                                        }}>
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            left: "50%", transform: "translate(-50%, 0px)",
                                            background: "#444444", padding: "15px 32px",
                                            borderRadius: "41px", color: "#FFFFFF50",
                                            display: "block",
                                            wordBreak: "keep-all", whiteSpace: "pre-wrap", textAlign: "center",
                                            minWidth: "320px",
                                            boxSizing: "border-box"
                                        }}>
                                            <span style={{
                                                fontSize: "16px", fontFamily: "Pretendard", lineHeight: "160%"
                                            }}>{"Knowd Project 내 온라인 전시\n대중을 설득하는 프로젝트 전시"}</span>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className={landingStyle["badge"]} style={{
                                position: "relative"
                            }} onMouseEnter={() => {
                                console.log("onMouseEnter")
                                setHoverTarget("STATION")
                            }}
                                onMouseLeave={() => {
                                    console.log("onMouseLeave")
                                    setHoverTarget("")
                                }}>
                                <span className={landingStyle["badge-trans"]} style={{
                                    textAlign: "center",
                                    display: "block", color: hoverTarget === "STATION" ? "#D44489" : "white",
                                    fontFamily: "sans-serif", marginBottom: "42px"
                                }}>
                                    KNOWD<br />
                                    Station
                                </span>
                                <img className={landingStyle["badge-trans"]} src={Bucket.badgeStation } />
                                {
                                    hoverTarget === "STATION"
                                    &&
                                    <div style={{
                                        position: "absolute",
                                        left: "50%", transform: "translate(-50%, 33px)",
                                        width: "100%", textAlign: "center"
                                    }}>
                                        <div style={{
                                            position: "relative",
                                            margin: "auto",
                                            width: "0px", height: "0px",
                                            borderWidth: "0px 8px 13px 8px",
                                            borderStyle: "solid", borderColor: "transparent transparent #444444 transparent "
                                        }}>
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            right: "calc(50% - 30%)",
                                            background: "#444444", padding: "15px 32px",
                                            borderRadius: "41px", color: "#FFFFFF50",
                                            display: "block",
                                            wordBreak: "keep-all", whiteSpace: "pre-wrap", textAlign: "center",
                                            minWidth: "320px",
                                            boxSizing: "border-box"
                                        }}>
                                            <span style={{
                                                fontSize: "16px", fontFamily: "Pretendard", lineHeight: "160%"
                                            }}>{"Knowd Project 작가들의 작품을\n만나볼 수 있는 공간"}</span>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    :
                    <div style={{
                        display: "flex", flexDirection: "column", gap: "48px",
                        marginBottom: "30px", alignSelf: "center"
                    }}>
                        <div id="platform-info" style={{
                            display: "flex", flexDirection: "column",
                        }}>
                            <img src={isTabletOrMobile ? Bucket.m_badgePlatform  : Bucket.badgePlatform } style={{
                                width: "112px", height: "72px",
                                marginBottom: "17px"
                            }} />
                            <div style={{
                                display: "flex", flexDirection: "row",
                                gap: "13px", alignItems: "center"
                            }}>
                                <span style={{
                                    whiteSpace: "pre-line",
                                    fontFamily: "sans-serif", fontWeight: 600,
                                    color: "#D5448980", fontSize: "14px",
                                    textAlign: "center"
                                }}>
                                    {"Knowd\nPlatform"}
                                </span>
                                <span style={{
                                    whiteSpace: "pre-line",
                                    fontFamily: "Pretendard", fontWeight: 500,
                                    color: "#FFFFFF50", fontSize: "12px", lineHeight: "160%"
                                }}>
                                    {"온라인 전시 및 오프라인 전시 정보 열람\n작가들이 제작하는 다양한 아트 컨텐츠"}
                                </span>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                        }}>
                            <div id="project-info" style={{
                                display: "flex", flexDirection: "column"
                            }}>
                                <img src={isTabletOrMobile ? Bucket.m_badgeProject  : Bucket.badgeProject } style={{
                                    width: "112px", height: "78px",
                                    marginBottom: "17px", alignSelf: "flex-end"
                                }} />
                                <div style={{
                                    display: "flex", flexDirection: "row",
                                    gap: "13px", alignItems: "center"
                                }}>
                                    <span style={{
                                        whiteSpace: "pre-line",
                                        fontFamily: "sans-serif", fontWeight: 600,
                                        color: "#D5448980", fontSize: "14px",
                                        textAlign: "center"
                                    }}>
                                        {"Knowd\nProject"}
                                    </span>
                                    <span style={{
                                        whiteSpace: "pre-line",
                                        fontFamily: "Pretendard", fontWeight: 500,
                                        color: "#FFFFFF50", fontSize: "12px", lineHeight: "160%",
                                    }}>
                                        {"Knowd Project 내 온라인 전시\n대중을 설득하는 프로젝트 전시"}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div id="station-info" style={{
                            display: "flex", flexDirection: "column",
                        }}>
                            <img src={isTabletOrMobile ? Bucket.m_badgeStation  : Bucket.badgeStation } style={{
                                width: "112px", height: "78px",
                                marginBottom: "17px"
                            }} />
                            <div style={{
                                display: "flex", flexDirection: "row",
                                gap: "13px", alignItems: "center"
                            }}>
                                <span style={{
                                    whiteSpace: "pre-line",
                                    fontFamily: "sans-serif", fontWeight: 600,
                                    color: "#D5448980", fontSize: "14px",
                                    textAlign: "center"
                                }}>
                                    {"Knowd\nStation"}
                                </span>
                                <span style={{
                                    whiteSpace: "pre-line",
                                    fontFamily: "Pretendard", fontWeight: 500,
                                    color: "#FFFFFF50", fontSize: "12px", lineHeight: "160%"
                                }}>
                                    {"Knowd Project 작가들의 작품을\n만나볼 수 있는 공간"}
                                </span>
                            </div>
                        </div>
                    </div>
                }
                <div ref={contentsRef} style={{
                    background: "#FFFFFF07",
                    padding: isTabletOrMobile ? "10px 16px" : "15px 23px",
                    marginLeft: "auto", marginTop: isTabletOrMobile ? undefined : "178px",
                    fontFamily: "Pretendard",
                    fontSize: isTabletOrMobile ? "12px" : "16px", wordBreak: "keep-all",
                    color: "#FFFFFF50", lineHeight: "136%",
                    width: isTabletOrMobile ? undefined : "491px"
                }}>
                    <span>
                        인류 역사를 돌이켜보면 기술은 우리에게 이루 말할 수 없는 편리함을 가져다주었지만, 가끔은 우리의 눈을 멀게도 하였습니다. 미래를 개척하는 기술의 폭발적인 진보, 그 한가운데 예술은 굳건해야한다는 신념하에 우리는 시작합니다. 대한민국, 나아가 전 세계 예술인들의 상상력이 폭창하는 이 곳, 예술과 대중이 이어지는 교점(Node)이자, 대중이 예술을 이해(Nod)하는 이 곳은 Knowd Ecosystem 입니다.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default AboutKnowd