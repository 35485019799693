import SizedBox from "./SizedBox";
import Bucket from "../ImageUriContainer";
import enrollmentStyle from "../Enrollment.css"

const Selector = ({ title, options, value, onChange, style }) => {
    return (
        <div style={{
            display: "flex", flexDirection: "column",
            ...style
        }}>
            <span style={{
                fontFamily: "Pretendard", fontSize: "15px", fontWeight: 600,
                textAlign: "center", whiteSpace: "pre-line", lineHeight: "140%"
            }}>{title}</span>
            <SizedBox height={35} />
            <div style={{
                display: "flex", flexDirection: "column",
                gap: "23px"
            }}>
                {/* map */}
                {options.map((option, index) => {
                    const isSelected = value === option;
                    return (
                        <div key={`${option}_${index}`}
                            className={enrollmentStyle["pointer"]}
                            style={{
                                display: "flex", flexDirection: "row",
                                alignItems: "center", justifyContent: "flex-start",
                                gap: "8px",
                            }} onClick={() => {
                                onChange(option);
                            }}>
                            <img src={isSelected ? Bucket.checkBtn : Bucket.uncheckBtn}
                                style={{
                                    width: "19px", height: "19px",
                                }} />
                            <span style={{
                                fontFamily: "Pretendard", fontSize: "12px", fontWeight: 500,
                                color: "black"
                            }}>{option}</span>
                        </div>)
                })}
            </div>
        </div>
    );
}

export default Selector;