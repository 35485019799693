// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LPuGygAZvdPeE6Hr0E8g:hover{\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://./src/pages/Enrollment/Enrollment.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB","sourcesContent":[".pointer:hover{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pointer": "LPuGygAZvdPeE6Hr0E8g"
};
export default ___CSS_LOADER_EXPORT___;
