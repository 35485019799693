import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import PippModal from "./components/PippModal";
import SizedBox from "./components/SizedBox";
import TextInput from "./components/TextInput";
import DivideLine from "./components/DivideLine";
import SexSelector from "./components/SexSelector";
import Selector from "./components/Selector";
import SnsLink from "./components/SnsLink";
import { _isEmailValid, _isPhoneValid } from "./functions/checkIsValid";
import { sendEnrollment } from "./functions/enrollmentAPI";
import Bucket from "./ImageUriContainer";
import LoadingSpinner from "./components/LoadingSpinner";
import enrollmentStyle from "./Enrollment.css";

const max360Width = {
    width: "100%",
    maxWidth: "360px",
}

const ArtistEnrollment = () => {
    const navigate = useNavigate();
    const [sex, setSex] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [age, setAge] = useState("");
    //useState number of art what you draw
    const [artNum, setArtNum] = useState("");
    //useState period of artwork
    const [artPeriod, setArtPeriod] = useState("");
    //UseState agree use personal information
    const [agree, setAgree] = useState(false);
    //useSTate personal infomation modal visible
    const [modalVisible, setModalVisible] = useState(false);

    const [linkList, setLinkList] = useState([
        undefined,
        undefined,
        undefined,
        undefined,
    ])

    const [loading, setLoading] = useState(false);

    const _onClickBackBtn = () => {
        navigate(-1);
    };

    const _toggleAgree = () => {
        setAgree(!agree);
    }

    //const arrow function that toggle modal visible
    const _toggleModalVisible = () => {
        setModalVisible(!modalVisible);
    };

    //arrow function that submit button is clicked
    const _onClickSubmitBtn = async () => {
        if (!_checkIsAllValid()) {
            return;
        }
        //define object that send to server
        const data = {
            gender: sex,
            name: name,
            phone: phone,
            email: email,
            age: age,
            artNum: artNum,
            artPeriod: artPeriod,
            linkList: linkList,
            agree: agree,
        }
        try {
            setLoading(true)
            await sendEnrollment(data, "artist");
            alert("제출되었습니다");
            navigate("/enrollment", {
                replace: true
            });
        } catch (error) {
            console.log(error)
            alert("정보 저장 중 오류 발생.")
        } finally {
            setLoading(false)
        }
    }

    const _checkIsAllValid = () => {
        //check sex value is not empty or undefined
        if (sex === "" || sex === undefined) {
            window.alert("성별을 선택해주세요");
            return false;
        }
        console.log(sex)
        //check name value is not empty or undefined
        if (name === "" || name === undefined) {
            alert("이름을 입력해주세요");
            return false;
        }
        console.log(name)

        //check phone value is not empty or undefined
        if (!_isPhoneValid(phone)) {
            alert("전화번호를 입력해주세요");
            return false;
        }
        //check email value is not empty or undefined
        if (!_isEmailValid(email)) {
            alert("이메일을 올바르게 입력해주세요");
            return false;
        }
        //check age value is not empty or undefined
        if (age === "" || age === undefined) {
            alert("연령대를 선택해주세요");
            return false;
        }
        //check artNum value is not empty or undefined
        if (artNum === "" || artNum === undefined) {
            alert("작품수를 선택해주세요");
            return false;
        }
        //check artPeriod value is not empty or undefined
        if (artPeriod === "" || artPeriod === undefined) {
            alert("작품기간을 선택해주세요");
            return false;
        }
        //check agree value is not empty or undefined
        if (!agree) {
            alert("개인정보 수집 및 이용에 동의해주세요");
            return false;
        }
        return true;
    }

    return (

        <div style={{
            display: "flex", flexDirection: "column",
            alignItems: "center", maxWidth: "720px", overflow: "hidden",
            position: "relative", margin: "auto"
        }}>
            <LoadingSpinner isOpen={loading} />
            <PippModal isOpen={modalVisible}
                onRequestClose={_toggleModalVisible} />
            <SizedBox height={42} />
            <div style={{
                ...max360Width, display: "flex", flexDirection: "column",
                boxSizing: "border-box",
            }}>
                <img onClick={_onClickBackBtn} src={Bucket.leftArrow} className={enrollmentStyle["pointer"]}
                    style={{
                        width: "24px", height: "24px",
                        alignSelf: "flex-start", marginLeft: "20px"
                    }} />
            </div>
            <SizedBox height={196} />
            <div style={{
                position: "relative"
            }}>
                <div style={{
                    zIndex: -1,
                    width: "458px", height: "458px",
                    position: "absolute",
                    top: "50%", left: "50%", transform: "translate(-50%,-50%)",
                    backgroundImage: `url(${Bucket.top})`,
                    backgroundSize: "458px 458px",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    backgroundPositionY: "center",
                }} />
                <span style={{
                    display: "flex",
                    fontFamily: "Pretendard", fontSize: "20px", fontWeight: 700, color: "black",
                    whiteSpace: "pre-line", textAlign: "center",
                    zIndex: 2,
                }}>
                    {`사전등록\nFor Artist`}
                </span>
            </div>
            <SizedBox height={159} />
            <SexSelector value={sex} onClick={(sex) => {
                setSex(sex)
            }} style={{ ...max360Width }} />
            <SizedBox height={50} />
            <TextInput title={"이름"} value={name}
                placeholder="이름을 입력해주세요."
                onTextChange={(text) => {
                    setName(text)
                }}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={37} />
            <TextInput title={"전화번호"} value={phone}
                placeholder="ex.01012341234"
                onTextChange={(text) => {
                    setPhone(text)
                }}
                type="number"
                inputMode="numeric"
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={37} />
            <TextInput title={"이메일"} value={email}
                placeholder="이메일을 입력해주세요."
                type="email"
                onTextChange={(text) => {
                    setEmail(text)
                }}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={59} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={age}
                onChange={(value) => {
                    setAge(value)
                }} title={"연령대를 알려주세요."}
                options={[
                    "10대",
                    "20세 - 27세",
                    "28세 - 32세",
                    "33세이상",
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={artNum}
                onChange={(value) => {
                    setArtNum(value)
                }} title={"지금까지 그린 작품의 수를 알려주세요."}
                options={[
                    "1 - 10점",
                    "11 - 20점",
                    "21점 이상"
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <Selector value={artPeriod}
                onChange={(value) => {
                    setArtPeriod(value)
                }} title={"작품활동을 한 기간을 알려주세요."}
                options={[
                    "0 - 6개월",
                    "7 - 12개월",
                    "1 - 2년",
                    "2년 이상"
                ]}
                style={{ width: "100%", maxWidth: "295px" }} />
            <SizedBox height={50} />
            <DivideLine />
            <SizedBox height={50} />
            <SnsLink style={{ width: "100%", maxWidth: "295px" }}
                linkList={linkList}
                onChange={(text, index) => {
                    var copyList = [...linkList]
                    copyList[index] = text
                    setLinkList(copyList)
                }}
                onClickIcon={(index) => {
                    var copyList = [...linkList]
                    if (linkList[index] !== undefined) {
                        copyList[index] = undefined
                    } else {
                        copyList[index] = ""
                    }
                    setLinkList(copyList)
                }} />
            <div style={{
                display: "flex", flexDirection: "row",
                alignItems: "center",
                gap: "44px"
            }}>
                <div style={{
                    display: "flex", flexDirection: "row",
                    alignItems: "center", gap: "12px",
                    fontFamily: "Pretendard",
                }}>
                    <span style={{
                        fontSize: "15px", fontWeight: 600
                    }}>{"개인정보 활용 동의"}</span>
                    <span onClick={_toggleModalVisible} className={enrollmentStyle["pointer"]} style={{
                        fontSize: "13px", fontWeight: 500,
                        color: "#838383"
                    }}>{"자세히 보기"}</span>
                </div>
                <img src={agree ? Bucket.checkBtn : Bucket.uncheckBtn}
                    style={{
                        width: "19px", height: "19px",
                    }} className={enrollmentStyle["pointer"]}
                    onClick={_toggleAgree} />
            </div>
            <SizedBox height={235} />
            <div style={{
                display: "flex", flexDirection: "column", whiteSpace: "pre-line",
                textAlign: "center",
                position: "relative"
            }}>
                <div style={{
                    zIndex: -1,
                    width: "458px", height: "458px",
                    position: "absolute",
                    top: "50%", left: "50%", transform: "translate(-50%,-50%)",
                    backgroundImage: `url(${Bucket.artistBtmGraphic})`,
                    backgroundSize: "458px 458px",
                    backgroundRepeat: "no-repeat",
                    backgroundPositionX: "center",
                    backgroundPositionY: "center",
                }} />
                <span style={{
                    lineHeight: "140%",
                    fontFamily: "Pretendard", fontSize: "15px", fontWeight: 600
                }}>{"설문에 응해 주셔서 감사합니다!\n\n"}
                    <span style={{
                        color: "#941949"
                    }}>{"Knowd는 2023년 3월 중 구글 플레이와\n앱스토어를 통해서 만나보실 수 있습니다.\n\n"}</span>
                    <span style={{
                        fontSize: "12px", fontWeight: 500
                    }}>{"이 설문에 참여해주신 작가분들은\n별도로 연락을 드릴 예정입니다.\n대중이 예술을 이해하는 공간, 예술과 대중이 만나는 공간,\nKnowd에서 만나요!"}</span>
                </span>
            </div>
            <SizedBox height={189} />
            <img src={Bucket.submitBtn} className={enrollmentStyle["pointer"]}
                onClick={_onClickSubmitBtn}
                style={{
                    width: "82px", height: "40px"
                }} />
            <SizedBox height={88} />
            <img style={{
                zIndex: -1,
                width: "692px", height: "251px",
                position: "absolute",
                bottom: 0,
            }} src={Bucket.blur} />
        </div>
    )
}

export default ArtistEnrollment;