import React,{InputHTMLAttributes} from 'react';
//set JSDoc
/**
 * @param {string} title
 * @param {string} placeholder
 * @param {function} onTextChange
 * //type is HTMLInputElement type
 * @param {string} type
 * @param {object} style
 * @returns {JSX.Element}
 * @constructor
 * @example
 * <TextInput
 * title="이름"
 * placeholder="이름을 입력해주세요"
 * onTextChange={(text)=>{console.log(text)}}
 * type="text"
 * style={{width:"100%"}}
 * />
 **/

const TextInput = ({
    title,placeholder,
    onTextChange,type, value,inputMode,
    style
}) => {
    return (
        <div style={{
            ...style,
            display:"flex",flexDirection:"column",
            gap:"9px"
        }}>
            <span style={{
                fontFamily:"Pretendard",fontSize:"15px", fontWeight:600
            }}>{title}</span>
            <input style={{
                border:"1px solid #838383",
                borderRadius:"20px",fontSize:"12px",
                padding:"17px 15px"
            }} defaultValue={value}
            onChange={(e)=>{
                onTextChange(e.target.value)
            }}
            type={type}
            inputMode={inputMode}
            pattern={type === "number" ? "[0-9]*" : ""}
            placeholder={placeholder}/>
        </div>
    )
}
export default TextInput